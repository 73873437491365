import { BaseModel } from 'modules/core/models/base-model';

export class AccountSettings extends BaseModel {

    name                  = null;
    email                 = null;
    entity                = null;
    current_password      = null;
    password              = null;
    password_confirmation = null;

}
