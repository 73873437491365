import { DialogController }         from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { BaseListViewModel }        from 'base-list-view-model';
import { ExternalTablesRepository } from 'modules/core/services/external-tables-repository';
import { AppContainer }             from 'resources/services/app-container';

@inject(AppContainer, ExternalTablesRepository, DialogController)
export class ExternalArtworkListingModal extends BaseListViewModel {

    listingId = 'external-tables-artworks-modal-listing';

    @bindable headerTitle    = 'listing.external-tables.artworks-listings';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.artworks',
        size:    'modal-lg',
        buttons: false,
    };

    fullData = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.searchArtworks(criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [
                {
                    tooltip: 'form.button.select',
                    label:   'form.button.select',
                    icon:    'icon-checkmark3',
                    action:  (row) => this.dialogController.ok(row),
                },
            ],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'matricula',
                    name:  'external_artworks.matricula',
                    title: 'form.field.car-plate',
                },
                {
                    data:  'code',
                    name:  'external_artworks.designacao',
                    title: 'form.field.designation',
                },
                {
                    data:    'arquivo_projeto',
                    name:    'external_artworks.arquivo_projeto',
                    title:   'form.field.arquivo_projeto',
                    display: false,
                },
                {
                    data:  'tipo_obra',
                    name:  'external_artworks.tipo_obra',
                    title: 'form.field.tipo_obra',
                },
                {
                    data:  'highway_description',
                    name:  'external_highways.description',
                    title: 'form.field.highway',
                },
                {
                    data:  'desc_concessao',
                    name:  'external_artworks.desc_concessao',
                    title: 'form.field.concession',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchArtworks(criteria) {
        return this.repository.searchArtworks(criteria);
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }
}
