import { DialogController }         from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { BaseListViewModel }        from 'base-list-view-model';
import { ExternalTablesRepository } from 'modules/core/services/external-tables-repository';
import { AppContainer }             from 'resources/services/app-container';

@inject(AppContainer, ExternalTablesRepository, DialogController)
export class ExternalJunctionListingModal extends BaseListViewModel {

    listingId = 'external-tables-junctions-modal-listing';

    @bindable headerTitle    = 'listing.external-tables.junctions-listings';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.junctions',
        size:    'modal-lg',
        buttons: false,
    };

    fullData = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.searchJunctions(criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [
                {
                    tooltip: 'form.button.select',
                    label:   'form.button.select',
                    icon:    'icon-checkmark3',
                    action:  (row) => this.dialogController.ok(row),
                },
            ],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'code',
                    name:  'external_junctions.code',
                    title: 'form.field.code',
                },
                {
                    data:  'descricao',
                    name:  'external_junctions.descricao',
                    title: 'form.field.description',
                },
                {
                    data:  'pk',
                    name:  'external_junctions.pk',
                    title: 'form.field.pk',
                },
                {
                    data:  'desc_ae',
                    name:  'external_highways.description',
                    title: 'form.field.highway',
                },
                {
                    data:  'concessao',
                    name:  'external_junctions.concessao',
                    title: 'form.field.concession',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchJunctions(criteria) {
        return this.repository.searchJunctions(criteria);
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }
}
