export class BaseModel {

    /**
     * Assign values to model
     *
     * @param object
     */
    assign(object = {}) {
        Object.keys(object).forEach((property) => {
            // doing this because arrays will be copied by reference
            if (object[property] instanceof Array) {
                if (!(this[property] instanceof Array)) {
                    this[property] = [];
                }

                if (!this[property]) {
                    console.error(`Add the property ${property} to the model ${this.constructor.name}`);
                }

                this[property].splice(0, this[property].length, ...object[property]);
            } else if (object[property] instanceof Object && this[property] instanceof BaseModel) {
                this[property].assign(object[property]);
            } else {
                this[property] = object[property];
            }
        });

        return this;
    }

    /**
     * Compares objects for equality
     *
     * @param object
     *
     * @returns {boolean}
     */
    equals(object = {}) {
        return Object.keys(object).every((property) => {
            if (object[property] instanceof Array) {
                if (object[property].length !== this[property].length) {
                    return false;
                }

                return object[property].sort().toString() === this[property].sort().toString();
            }

            if (this[property] instanceof BaseModel) {
                return this[property].equals(object[property]);
            }

            if (typeof object[property] !== 'undefined' && object[property] !== null && object[property].length === 0) {
                object[property] = null;
            }

            if (typeof this[property] !== 'undefined' && this[property] !== null && this[property].length === 0) {
                if (typeof this[property] === 'string') {
                    object[property] = this[property];
                } else {
                    this[property] = null;
                }
            }

            let isEqual = object[property] === this[property];

            if (!isEqual) {
                // input 'number's don't work as they should, if the value is changed it will be treated as a string
                if (typeof object[property] === 'number') {
                    isEqual = object[property] === Number(this[property]);
                }

                if (!isEqual) {
                    console.warn(`Verify the property ${property} of the model ${this.constructor.name}`);
                }
            }

            return isEqual;
        });
    }

    /**
     * Returns the differences between the current instance and the passed object.
     *
     * @param object
     * @returns {object} Differences
     */
    diff(object = {}) {
        const differences = {};

        Object.keys(this).forEach((property) => {
            if (object[property] === undefined) {
                // Property exists in this but not in object (deleted/missing)
                differences[property] = {
                    type:        'deleted',
                    thisValue:   this[property],
                    objectValue: undefined,
                };
            } else if (this[property] instanceof Array) {
                // Handle array comparison
                if (!Array.isArray(object[property]) || object[property].length !== this[property].length || object[property].sort().toString() !== this[property].sort().toString()) {
                    differences[property] = {
                        type:        'array-mismatch',
                        thisValue:   this[property],
                        objectValue: object[property],
                    };
                }
            } else if (this[property] instanceof BaseModel) {
                // Handle BaseModel comparison
                const nestedDiff = this[property].diff(object[property]);
                if (Object.keys(nestedDiff).length > 0) {
                    differences[property] = {
                        type:              'model-mismatch',
                        thisValue:         this[property],
                        objectValue:       object[property],
                        nestedDifferences: nestedDiff,
                    };
                }
            } else if (this[property] !== object[property]) {
                // Primitive value comparison
                differences[property] = {
                    type:        'value-mismatch',
                    thisValue:   this[property],
                    objectValue: object[property],
                };
            }
        });

        // Check for properties that exist in object but not in this (added properties)
        Object.keys(object).forEach((property) => {
            if (this[property] === undefined) {
                differences[property] = {
                    type:        'added',
                    thisValue:   undefined,
                    objectValue: object[property],
                };
            }
        });

        return differences;
    }
}
