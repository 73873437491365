import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ExternalTablesRepository {
    baseUrl = 'external-tables';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all highways
     *
     * @param criteria
     * @return {*}
     */
    searchHighways(criteria) {
        return this.httpClient.post(`${this.baseUrl}/highways/search`, criteria);
    }

    /**
     * Returns all subsections by search criteria
     *
     * @param criteria
     * @return {*}
     */
    searchAllSubsections(criteria) {
        return this.httpClient.post(`${this.baseUrl}/subsections/search-all`, criteria);
    }

    /**
     * Returns all junctions
     *
     * @param criteria
     * @return {*}
     */
    searchJunctions(criteria) {
        return this.httpClient.post(`${this.baseUrl}/junctions/search`, criteria);
    }

    /**
     * Returns all artworks
     *
     * @param criteria
     * @return {*}
     */
    searchArtworks(criteria) {
        return this.httpClient.post(`${this.baseUrl}/artworks/search`, criteria);
    }

    /**
     * Returns all branches
     *
     * @param criteria
     * @return {*}
     */
    searchBranches(criteria) {
        return this.httpClient.post(`${this.baseUrl}/branches/search`, criteria);
    }
}
